import React, {useEffect} from 'react';
import {createGlobalStyle} from "styled-components";
import {graphql} from "gatsby";
import {connect, useDispatch} from "react-redux";
import TopBanner from "../components/common/top-banner/top-banner";
import ExtendedContent from "../components/extendedContent";
import SEO from "../components/seo";
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"
import {setTopColors} from "../state/app";

const GlobalStyles = createGlobalStyle`
	body {
		background: linear-gradient(${({ theme }) => theme.colors.mainPurple} 400px,${({ theme }) => theme.colors.mainWhite} 400px);
	}
`

const DocumentFlowPage = ({ data, pageContext, topColors}) => {
    const entries = data.entries?.nodes?.[0] || "";
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setTopColors( entries.archeeTopKolory || topColors));
        saveUrlsToLocalStorage(pageContext);
    },[])
    return(
        <>
            <GlobalStyles/>
            <TopBanner banner={entries.archeeBaner} topColors={entries.archeeTopKolory}/>
            <ExtendedContent data={entries.archeeTrescPodstrony}/>
        </>
    )
}
export default connect(state => ({
    topColors: state.app.topColors,
}), null)(DocumentFlowPage)

export const query = graphql`
    query($remoteId: ID, $langKey: String) {
            seo: allCraftArcheeDocumentFlowArcheeDocumentFlowEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes{
                    ...seoDocument
                }
            }
           entries: allCraftArcheeDocumentFlowArcheeDocumentFlowEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes {
                    title,
                    archeeTopKolory,
                    archeeBaner {
                        ...banner,
                    }
                    archeeTrescPodstrony {
                        __typename
                        ...margines,
                        ...naglowek,
                        ...naglowekIOpis,
                        ...modulyGraficzne,
                        ...component,
                        ...on Craft_archeeTrescPodstrony_naglowek2_BlockType {
                            naglowek2
                        }
                        ...on Craft_archeeTrescPodstrony_checkAlso_BlockType {
                            wybierzPodstrone {
                                title
                                __typename
                            }
                        }
                    }
                    seoTitle
                }
            }
    }`

export function Head({ data }) {
    const seoData = data?.seo?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}